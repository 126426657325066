import React from "react";

import Video from "../video/videoContainer";
const Mereos_2 = ({ videoSrc, type }) => {
  return (
    <>
      <div className={`font-sans w-full `}>
        <div className="my-10 w-11/12 md:w-4/5 mx-auto relative">
          {/* <img
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/ellipse_icon.svg"
            alt="ellipses"
            className="absolute -top-14 -left-16 hidden md:flex"
          /> */}
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/images/background_dots_yellow.svg"
            alt="dots"
            className="absolute w-64 bottom-14 -left-20 hidden md:flex"
          />
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/images/dots_3.svg"
            alt="dots"
            className="absolute  top-5 -right-32 hidden md:flex"
          />
          <div>
            <Video
              videoType={type}
              videoSrcURL={videoSrc}
              videoTitle="Official Music Video on YouTube"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Mereos_2;
