import React from "react";

const Video = ({ videoSrcURL, videoTitle, page, ...props }) => (
  <div className="video z-50 relative">
    <iframe
      className="rounded-2xl"
      src={videoSrcURL}
      width="100%"
      height={page === "about" ? "650px" : "550px"}
      frameBorder="0"
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
);

export default Video;
