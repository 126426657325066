import React from "react";
import Video from "../video/video";
const Mereos = ({ page, videoSrc }) => {
  return (
    <>
      <div className={`font-sans w-full `}>
        <div className="my-10 w-11/12 md:w-4/5 mx-auto relative">
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/ellipse_icon.svg"
            alt="ellipses"
            className="absolute -top-14 -left-16 hidden md:flex z-10"
          />
          {page === "privacy" ? null : (
            <img
              src="https://d2lxkizvrhu4im.cloudfront.net/images/background_dots_yellow.svg"
              alt="dots"
              className="absolute  -bottom-14 -left-16 hidden md:flex z-10"
            />
          )}
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/images/background_dotted_yellow_2.svg"
            alt="dots"
            className="absolute w-36  -top-14 -right-10 hidden md:flex z-10"
          />
           <Video
            type="HomePage"
            videoSrcURL={videoSrc}
            videoTitle="Official Music Video on YouTube"
            
          />
        </div>
      </div>
    </>
  );
};

export default Mereos;
