import React, { useState } from "react";

import * as classes from "./faqs.module.scss";
import { useTranslation } from 'react-i18next';

const FAQS = ({ title, faqsType, data }) => {
  const [ListData, setListData] = useState([...data]);
  const {t} = useTranslation();
  const [activeStep, setActiveStep] = useState([
    ...Array(ListData.length).fill(false),
  ]);

  const statehandler = (index) => {
    let array = [...Array(ListData.length).fill(false)];
    array[index] = !activeStep[index];
    setActiveStep(array);
  };

  return (
    <div className="w-full md:w-full ">
      <div className="text-2xl md:text-5xl font-semibold text-center mb-10 md:mb-20 leading-normal">
        {t(title)}
      </div>
      <div className="w-full">
        <nav className="w-full list-none flex flex-col  h-full justify-around ">
          <li className={`border-0 my-0 mx-0 p-5 cursor-pointer rounded-t-2xl text-white text-base font-medium ${classes.faqsHeader}`}>
            {faqsType}
          </li>
          {
            ListData.map((ListStep, index) => (
              <li
                className={`border-2 space-y-2  border-gray-100 my-0 mx-0 p-5 cursor-pointer ${
                  index === ListData.length - 1 ? "border-b-2" : "border-b-0"
                } `}
              >
                <div
                  className=" font-bold flex flex-row justify-between items-center  text-left"
                  onClick={() => statehandler(index)}
                >
                  <div className="w-3/4 font-semibold text-sm md:text-lg">
                    {" "}
                    {t(ListStep.title)}
                  </div>
                  <div
                    className={` ${
                      activeStep[index] ? " transform rotate-180" : ""
                    }`}
                  >
                    <img
                      alt="logos"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="my-0 h-2"
                    />
                  </div>
                </div>
                <div
                  className={`${
                    activeStep[index] ? "flex flex-col" : "hidden"
                  }`}
                >
                  <div className=" text-gray-500 text-sm md:text-base font-normal ">
                    {t(ListStep.description)}
                  </div>
                </div>
              </li>
            ))
          }
        </nav>
      </div>
    </div>
  );
};

export default FAQS;
