import React from "react";
import PlatformTopBanner from "../top_banner/platform_top_banner";
// import GetLatest from "../latest/latest";
import Mereos from "../what_is_Mereos/mereos";
// import FeaturedSingleBlog from "../featured/featuredSingle";
import PrivacyFeatures from "../features/privacyFeatures";
// import StripeFeatures from "../features/stripeFeatures";
import Subscribe2 from "../subscribe/subscribe_2";
import Review from "../reviews/reviews";
import FAQS from "../faqs/faqs";
import Help from "../help/help";
import Mereos_2 from "../what_is_Mereos/mereos_2";
import { useTranslation } from 'react-i18next';

const PlatformPrivacy = () => {
  const {t} = useTranslation();
  const ListData = [
    {
      title:
        `${t('where_is_candidate_data_stored')}`,
      description:
        `${t('candidate_data_is_encrypted_and_stored')}`,
    },
    {
      title:
        `${t('what_data_does_Mereos_collect')}`,
      description:
        `${t('depending_on_the_methods_chosen_by')}`,
    },
    {
      title: `${t('what_happens_to_my_data_once_the_evaluation')}`,
      description:
        `${t('after_the_evaluation_the_collected_data_is_stored')}`,
    },
    {
      title: `${t('can_candidate_data_be_transferred_outside_the_European_union')}`,
      description:
        `${t('all_data_collected_by_mereos_on_behalf')}`,
    },
    {
      title: `${t('i_dont_want_my_assessment')}`,
      description:
        `${t('we_recommend_that_candidates_take_their_assessment')}`,
    },
    {
      title: `${t('is_the_mereos_extension_spy_technology')}`,
      description:
        `${t('no_to_begin_mereos_repeatedly')}`,
    },
  ];

  const PaymentData = [
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/globe_in_message_icon_with_bg.svg",
      title: `${t('biometric_processing')}`,
      description:
        `${t('mereos_respects_the_recommendations')}`,
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/headphone_icon_with_bg.svg",
      title: `${t('remote_control')}`,
      description:
        `${t('mereos_respects_the')}`,
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/thumbs_up_icon_with_bg.svg",
      title: `${t('end_to_end_encryption')}`,
      description:
        `${t('candidate_data_is_encrypted')}`,
    },
  ];

  const ReviewsData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/profile.png",
      name: "Sabine Romeu",
      designation: "DRH Cours Legendre",
      reviewTitle: "Un seul avis compte: celui de nos clients",
      review:
        "Il devenait de plus en plus difficile de coordonner le recrutement de milliers d’enseignants en présentiel. Mereos nous a apporté la flexibilité qui nous manquait. Depuis que nous utilisons Mereos, notre taux de conversion est exceptionnel.",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/profile.png",
      name: "Sabine Romeu",
      designation: "DRH Cours Legendre",
      reviewTitle: "Un seul avis compte: celui de nos clients",
      review:
        "Il devenait de plus en plus difficile de coordonner le recrutement de milliers d’enseignants en présentiel. Mereos nous a apporté la flexibilité qui nous manquait. Depuis que nous utilisons Mereos, notre taux de conversion est exceptionnel.",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/profile.png",
      name: "Sabine Romeu",
      designation: "DRH Cours Legendre",
      reviewTitle: "Un seul avis compte: celui de nos clients",
      review:
        "Il devenait de plus en plus difficile de coordonner le recrutement de milliers d’enseignants en présentiel. Mereos nous a apporté la flexibilité qui nous manquait. Depuis que nous utilisons Mereos, notre taux de conversion est exceptionnel.",
    },
  ];

  return (
    <>
      <div className={`conatiner font-sans`}>
        <PlatformTopBanner
          pageType={"left"}
          pageTitle={t('personal_data')}
          title={t('your_data_your_rules')}
          description={t('mereos_is_designed_to_go_beyond')}
          imageSrc={"https://d2lxkizvrhu4im.cloudfront.net/images/Man_with_key_infront_laptop_with_lock.svg"}
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-10/12 `}>
          <div className="my-20">
            <PrivacyFeatures
              title="Une sécurité totale, aucun stress"
              DataArray={PaymentData}
            />
          </div>

          <div className={"mt-7 mt-5"}>
            <FAQS
              title={t('the_most_frequently_asked_questions_about_our_data')}
              faqsType='FAQ'
              data={ListData}
            />
          </div>

          <div className="my-10 md:my-32">
            <div className="space-y-2 md:space-y-10">
              {" "}
              <div className="text-2xl md:text-5xl  text-center  font-bold md:w-4/5 mx-auto">
                {t('how_does_mereos_actually_protect_private_data')}
              </div>
              <div
                className={`  font-medium text-sm md:text-xl  text-center mx-auto md:w-10/12 my-4  text-gray-500  `}
              >
                {t('maintaining_the_integrity_of_your_reviews')}
              </div>
            </div>
            {/* <Mereos_2
              type={"online"}
              videoSrc="https://www.youtube.com/embed/8OMbVXd8tgw"
            /> */}
            <Mereos
              page={"privacy"}
              videoSrc="https://www.youtube.com/embed/8OMbVXd8tgw"
            />
          </div>
        </div>
        {/* <div>
          <Review Data={ReviewsData} />
        </div> */}
        <div className={`mx-auto hidden md:block w-11/12 lg:w-5/6 md:my-20`}>
          <Help />
        </div>
        <Subscribe2 />
      </div>
    </>
  );
};

export default PlatformPrivacy;
