import { Link } from "gatsby";
import React from "react";
import { useTranslation } from 'react-i18next';

const Help = ({ title, description, descriptionStyle, button }) => {
  const {t} = useTranslation();
  return (
    <div className="w-full  flex flex-col  items-center md:flex-row justify-between bg-gradient-to-r from-yellow-400 to-yellow-500 rounded-lg font-sans shadow-xl lg:h-56 space-y-5 py-10">
      <div className="flex flex-col justify-center text-center md:text-left md:text-start items-center lg:justify-start lg:items-end lg:w-2/3 ">
        <div className="w-11/12 flex  flex-col items-start">
          <div className="text-2xl md:text-4xl text-white py-2 font-bold">
            { title || t('discover_our_help_center')}
          </div>
          <div className="text-white font-medium text-sm md:text-lg text-center md:text-left lg:w-9/12" style={descriptionStyle}>
            { description || t('consult_the_answers_to_the_questions')}
          </div>
        </div>
      </div>
      {
        button &&
        <div className="w-11/12 lg:w-1/3 flex flex-row justify-center items-center h-12">
          <button className="bg-white text-black text-sm md:text-xl w-full lg:w-2/3 h-full mx-auto rounded-sm font-semibold">
            <Link to={button.href || "https://mereos.zendesk.com/hc/fr"}>
              {button.text || "Centre d’Aide"}
            </Link>
          </button>
        </div>
      }
    </div>
  );
};

export default Help;
