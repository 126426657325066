import React, { useState, useEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";

import * as classes from "./reviews.module.scss";
import { useTranslation } from 'react-i18next';

const Review = ({ Data }) => {
  const { t } = useTranslation();
  const [mapData, setMapData] = useState([...Data]);
  const [activeReview, setActiveReview] = useState(0);

  const breakpoint = useBreakpoint();
  const [size, setSize] = useState(3);
  
  useEffect(() => {
    breakpoint.md ? setSize(1) : setSize(3);
  }, [breakpoint]);

  const SlicedArray = mapData.slice(0, size);

  return (
    <>
      <div
        className="bg-cover w-full  h-full pt-32 pb-16 lg:py-32 font-sans relative"
        style={{
          backgroundImage: `${
            breakpoint.l
              ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/background_light_mobile.svg")`
              : `url("https://d2lxkizvrhu4im.cloudfront.net/images/background_light.svg")`
          }`,
        }}
      >
        <div className="flex flex-col justify-center text-center w-11/12 mx-auto lg:w-10/12">
          <div className="md:mx-auto  text-center">
            <div className="font-bold my-2 text-gray-500">
              {t('testimonials')}
            </div>
            <div className="text-3xl md:text-5xl my-6 font-semibold w-10/12 text-center mx-auto">
              {t(SlicedArray[activeReview].reviewTitle)}
            </div>
            <div className="text-sm font-normal lg:font-medium md:text-xl my-4 leading-relaxed w-11/12 md:w-3/4 mx-auto">
              <span className={classes.startQuote}>
                <img
                  alt="yellow light icon"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/start_quotes_light_yellow_icon.svg"
                  className="m-0"
                />
                <span style={{ color: "#fffdf4" }}>A </span>
              </span>
              {t(SlicedArray[activeReview].review)}
              <span className={classes.endQuote}>
                <span style={{ color: "#fffdf4" }}>A </span>
                <img
                  alt="yellow light icon"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/end_quotes_light_yellow_icon.svg"
                  className="m-0"
                />
              </span>
            </div>

            <div className="font-semibold text-sm md:text-xl text-yellow-500 my-4">
              <Link to="/notre-mission">{t('discover_our_history')} &gt;</Link>
            </div>

            <div className="flex flex-row items-center my-10">
              <div
                className={`w-1/3  border-b-2 ${
                  activeReview === 0 ? "border-yellow-500" : "border-yellow-100"
                }`}
              ></div>
              <div
                className={` w-1/3  border-b-2 ${
                  activeReview === 1 ? "border-yellow-500" : "border-yellow-100"
                }`}
              ></div>
              <div
                className={`w-1/3 ${
                  activeReview === 2 ? "border-yellow-500" : "border-yellow-100"
                } border-b-2 space-y-0`}
              ></div>
            </div>
          </div>

          <div className="lg:w-full  flex flex-col md:flex-row items-center md:justify-between ">
            {
              SlicedArray.map((i, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setActiveReview(index);
                    }}
                    className="flex flex-col  md:flex-row  items-center justify-center min-w-max"
                  >
                    <div className="border-2 w-10 rounded-3xl border-yellow-500 p-0 bg-yellow-500">
                      <img
                        alt="reviews"
                        src={i.imgSrc}
                        className=" m-0 w-full p-0"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                    <div className="md:ml-4">
                      <div className="text-sm md:text-lg font-semibold flex flex-col items-center md:items-start  ">
                        {i.name}
                      </div>
                      <div className="text-xs lg:text-base">{i.designation}</div>
                    </div>
                  </div>
                );
              })
            }
          </div>

          <Link to="/client" className="flex items-center justify-center mt-11">
            <button className="bg-yellow-300 mx-auto border-0 px-7 lg:px-10 py-4 lg:py-5 cursor-pointer text-sm md:text-xl font-semibold rounded-lg focus:outline-none">
              {t('all_testimonials')}
            </button>
          </Link>
        </div>
        <div
          className="absolute bg-white  w-full  h-6  lg:h-20 bottom-0"
          style={{
            clipPath: "polygon(100% 0%, 0% 100%, 100% 100%)",
          }}
        ></div>
      </div>
    </>
  );
};

export default Review;
