import * as React from "react";

import PlatformPrivacyComponent from "../components/platform/platform_privacy";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const PlatformPrivacy = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={t('data_protection')}
        description={t('keep_full_control_of_your_candidate_data')}
      />
      <Layout>
        <PlatformPrivacyComponent />
      </Layout>
    </>
  )
}

export default PlatformPrivacy
