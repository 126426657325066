import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from 'react-i18next';

const PrivacyFeatures = ({title, DataArray }) => {
  const breakpoints = useBreakpoint();
  const {t} = useTranslation();
  const [showStatus, setShowStatus] = useState(0);
  return (
    <div className="">
      <div className="flex justify-center items-center md:justify-between w-full mx-auto relative my-10">
        <img 
          alt="right star "
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/right_star_icon.svg"
          className="hidden md:block relative  -left-20"
        />
        <div className="text-3xl md:text-5xl font-semibold mx-auto text-center">
          {t(title)}
        </div>
        <img 
          alt="left star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block relative -right-20"
        />
      </div>

      <div className="flex flex-col md:flex-row flex-wrap  items-center">
        {
          DataArray.map((Data, index) => (
            <div className="w-11/12 md:w-1/3 my-5 flex flex-col items-center space-y-2">
              <div className=" text-center md:text-left flex flex-row  justify-center md:justify-start ">
                <img alt="data" src={Data.icon} className="m-0" />
              </div>

              <div className="font-semibold text-lg md:text-xl text-center w-2/3 md:w-full">
                {t(Data.title)}
              </div>
              {/* <div
                className={`text-sm text-gray-500 text-center  flex flex-col  ${
                  !breakpoints.md
                    ? null
                    : showStatus === index + 1
                    ? "flex"
                    : "hidden"
                }`}
              > */}
                <div className="flex flex-col md:flex-row items-center text-center text-sm md:text-base  mx-auto  " dangerouslySetInnerHTML={{__html: Data.description}} >
                  {/* {Data.description} */}
                </div>
              {/* </div> */}
              {/* <div className="md:hidden flex flex-row justify-center md:justify-start text-yellow-500 cursor-pointer">
                {showStatus === index + 1 ? (
                  <div
                    className="flex flex-row items-center space-x-2"
                    onClick={() => {
                      setShowStatus(0);
                    }}
                  >
                    <span>Hide</span>{" "}
                    <img
                      alt="data"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="m-0 transform rotate-180 -translate-y-0.5"
                    />
                  </div>
                ) : (
                  <div
                    className="flex flex-row items-center space-x-2"
                    onClick={() => {
                      setShowStatus(index + 1);
                    }}
                  >
                    <span>Show More</span>{" "}
                    <img
                      alt="data"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                      className="m-0 "
                    />
                  </div>
                )}
              </div> */}
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default PrivacyFeatures;
