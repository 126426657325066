import React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import * as classes from "./top_banner.module.scss";

const BannerWrapper = ({largeBgImg, nonlargeBgImg, children}) => {
  const breakpoints = useBreakpoint();
  largeBgImg="https://d2lxkizvrhu4im.cloudfront.net/images/home_top_banner.svg";
  nonlargeBgImg="https://d2lxkizvrhu4im.cloudfront.net/images/top_banner_backround.svg";
  
  return (
    <div
      className={`text-white bg-cover bg-no-repeat   ${classes.background_wrapper}`}
      style={{ backgroundImage: `${breakpoints.l ? `url("${largeBgImg}")`:`url("${nonlargeBgImg}")`}` }}
    >
      {children}
    </div>
  )
};

export default BannerWrapper;
